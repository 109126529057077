import { Modal, Timeline, message } from 'antd';
import { unsecured } from 'apis';

const { Item } = Timeline;

/**
 * 状态轨迹
 */
export const tracks = async (orderId: string) => {
  const res = await unsecured.order.getTracks(orderId);
  if (res.ok) {
    if (!res.data.length) {
      message.error('暂无进度信息');
      return;
    }
    Modal.confirm({
      title: '订单办理进度状态',
      content: (
        <Timeline style={{ marginTop: 30 }}>
          {res.data.map((i: any) => {
            return (
              <Item>
                <p>{i.status}</p>
                <p>{i.description}</p>
                <p>{i.createTime}</p>
              </Item>
            );
          })}
        </Timeline>
      ),
    });
  }
};

/**
 * 取消订单
 */
export const cancel = (itemId: string, onOk?: () => void) => {
  Modal.confirm({
    title: '取消订单',
    content: '您将取消该笔申请，您确定要取消吗？',
    onOk: async () => {
      const res = await unsecured.order.cancel(itemId);
      if (res.ok) {
        message.success('订单取消成功');
        onOk?.();
      }
    },
  });
};
