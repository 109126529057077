import { useMemo } from 'react';
import { Statistic, Row, Typography, Divider } from 'antd';
import { PageDetails, useDetails, TableMobile } from 'components';
import { combine } from 'stores';
import { user } from 'apis';
import { createColumns } from './config';
import { Recharge } from './recharge';

const Page = combine(() => {
  const { states, setStates, setLoading, pageDetailsProps, getData } = useDetails({
    defaultData: [],
    getData: async () => {
      setLoading('获取账户余额');
      const res = await user.balance.getList();
      setLoading(false);
      if (res.ok) {
        const { data } = res;
        const { add } = await import('mathjs');
        let totalBalance: any = 0;
        let totalFreeze: any = 0;
        data.forEach((i: any) => {
          totalBalance = add(totalBalance, i.balance || 0);
          totalFreeze = add(totalFreeze, i.frozenAmount || 0);
        });
        setStates({ data, totalBalance, totalFreeze });
      }
    },
  });
  const { data, totalBalance, totalFreeze } = states;

  // 生成表格配置数据
  const columns = useMemo(() => createColumns({ getData }), []);

  return (
    <PageDetails
      {...pageDetailsProps}
      onBack={false}
      headerChildren={
        <Row style={{ maxWidth: 600 }}>
          <Statistic title="总余额(元)" prefix="¥" value={totalBalance} loading={totalBalance === undefined} />
          <div style={{ flex: 1 }} />
          <Statistic title="总冻结(元)" prefix="¥" value={totalFreeze} loading={totalFreeze === undefined} />
        </Row>
      }
    >
      <Typography.Title level={5} style={{ marginTop: 30, marginBottom: 0 }}>
        余额明细
      </Typography.Title>
      <Divider />
      <div style={{ marginBottom: 20 }}>
        <TableMobile columns={columns} dataSource={data} rowKey="walletName" size="small" scroll={{ y: undefined }} />
      </div>
      <Recharge />
    </PageDetails>
  );
});

export default Page;
