import { FC, RefObject } from 'react';
import { useStates } from 'seasoning/es/hooks';
import { TForm, FormItem } from 'common/antd';
import { SelectArea, SelectMortgagee } from 'components';

export const BasicInformation: FC<{ mortgageeAddress?: number[]; formRef: RefObject<TForm> }> = ({
  mortgageeAddress = [],
  formRef,
}) => {
  const { states, setStates } = useStates({ disabled: false });
  const { disabled } = states;
  const selectedArea = +mortgageeAddress[mortgageeAddress.length - 1];

  return (
    <>
      <FormItem label="抵押权人所在地" name="mortgageeAddress" placeholder required select hidden={disabled}>
        <SelectArea />
      </FormItem>
      <FormItem
        label="抵押权人"
        name="mortgagee"
        placeholder
        required="请选择机构网点作为抵押权人"
        select
        formItemProps={{
          extra: <span style={{ color: 'black' }}>注！请先选中抵押权人所在地，用于查询机构</span>,
        }}
      >
        <SelectMortgagee
          disabled={!selectedArea || disabled}
          area={selectedArea}
          onData={(data) => {
            if (data?.length === 1) {
              const { mortgageeName, outletsName } = data[0];
              setStates({ disabled: true });
              formRef.current!.form.setFieldsValue({ mortgagee: [mortgageeName, outletsName] });
            }
          }}
        />
      </FormItem>
    </>
  );
};
