import { message } from 'antd';
import { FormItem, Password } from 'common/antd';
import { PageForm, useInfo } from 'components';
import { combine } from 'stores';
import { user } from 'apis';
import { Captcha } from './captcha';

const rule = {
  pattern: /^[A-Za-z0-9-_]{4,50}$/,
  message: '请输入4-50位字母、数字、下划线、“-”的组合字符',
};

const Page = combine(({ stores }) => {
  const { phone } = stores.user.info;
  const { setLoading, pageFormProps, formRef } = useInfo({
    onFinish: async ({ confirmpayPwd, ...values }: any) => {
      setLoading('正在保存');
      const res = await user.payPassword.edit({ phone, ...values });
      if (!res.ok) return setLoading(false);
      setLoading(false);
      message.success('修改支付密码成功');
    },
  });

  return (
    <PageForm {...pageFormProps} onBack={false}>
      <FormItem label="手机号">{phone || '未登录'}</FormItem>
      <FormItem label="验证码">
        <div className="align-center">
          <FormItem
            name="code"
            placeholder="请输入验证码"
            required
            maxLength={4}
            validator={(v: string) => {
              if (v?.length !== 4) return '请输入4位验证码';
            }}
            formItemProps={{ noStyle: true }}
          />
          <Captcha />
        </div>
      </FormItem>
      <FormItem label="支付密码" name="payPwd" placeholder required maxLength={50} rules={[rule]}>
        <Password />
      </FormItem>
      <FormItem
        label="确认密码"
        name="confirmpayPwd"
        placeholder
        required
        maxLength={50}
        rules={[rule]}
        validator={(v) => {
          const payPwd = formRef.current!.form.getFieldValue('payPwd');
          if (payPwd !== v) return '两次输入密码不一致';
        }}
      >
        <Password />
      </FormItem>
    </PageForm>
  );
});

export default Page;
