import { useEffect } from 'react';
import { Cascader, TCascaderProps } from 'common/antd';
import { useStates } from 'seasoning/es/hooks';
import { combine } from 'stores';
import { user } from 'apis';

/**
 * 城市选择器
 */
export const SelectArea = combine<TCascaderProps & { valueKey?: string }>(({ stores, valueKey, ...props }) => {
  const { states, setStates } = useStates({
    options: [] as any[],
    loading: false,
  });
  const { options, loading } = states;

  useEffect(() => {
    stores.user.onLogin(async () => {
      setStates({ loading: true });
      const res = await user.getArea();
      if (res.ok) {
        const data = res.data.filter((i: any) => i.id);
        stores.unsecured.setArea(data);
        setStates({ options: areaToOptions(data, valueKey), loading: false });
      }
    });
  }, []);

  return <Cascader options={options} loading={loading} changeOnSelect {...props} />;
});

/**
 * 城市数据转树状数据
 */
export const areaToOptions = (data: any[], valueKey = 'id'): any[] => {
  const getOption = (item: any) => {
    return { value: item[valueKey], label: item.name };
  };

  return (
    data
      ?.filter((i: any) => [44].includes(i.id))
      .map((i: any) => {
        const province = { ...getOption(i), children: [] as any[] };
        if (i.cityList?.[0]?.[valueKey] === i[valueKey]) {
          province.children = i.cityList[0].countyList.map(getOption);
        } else {
          province.children = i.cityList.map((i: any) => {
            const children = i.countyList.map(getOption);
            return { ...getOption(i), children };
          });
        }
        return province;
      }) || []
  );
};
