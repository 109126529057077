import { useEffect, useMemo } from 'react';
import { Cascader, TCascaderProps } from 'common/antd';
import { useStates } from 'seasoning/es/hooks';
import { combine } from 'stores';
import { unsecured } from 'apis';

type TSelectMortgageeProps = Partial<TCascaderProps> & {
  onData?: (data: any[]) => void;
  area?: number;
};

/**
 * 抵押权人选择器
 */
export const SelectMortgagee = combine<TSelectMortgageeProps>(({ stores, onData, area, ...props }) => {
  const { states, setStates } = useStates({
    data: [] as any[],
    loading: false,
  });
  const { data, loading } = states;

  useEffect(() => {
    stores.user.onLogin(async () => {
      setStates({ loading: true });
      const res = await unsecured.getMortgagee();
      if (res.ok) {
        const data = res.data || [];
        onData?.(data);
        stores.unsecured.setMortgagee(data);
        setStates({ data, loading: false });
      }
    });
  }, []);

  // 根据选中的地址筛选抵押权人
  const options = useMemo(() => mortgageeToOptions(data, area), [area, data.length]);

  return <Cascader options={options} loading={loading} {...props} />;
});

/**
 * 抵押权人列表转级联数据
 */
export const mortgageeToOptions = (data: any[], area?: number) => {
  const options: any[] = [];
  const areaRegExp = new RegExp(`^${area}`);
  data.forEach?.((i: any) => {
    // 选中城市时过滤抵押权人列表
    if (area && !areaRegExp.test(i.areaCode)) return;
    // 判定该机构是否已存在
    const option = options.find((o) => o.value === i.mortgageeName);
    // 转化后的网点数据
    const child = { ...i, value: i.outletsName, label: i.outletsName };
    if (option) {
      // 添加网点
      option.children.push(child);
    } else {
      // 添加机构和网点
      options.push({ ...i, value: i.mortgageeName, label: i.mortgageeName, children: [child] });
    }
  });
  return options;
};
