import { Button, Space } from 'antd';
import { FieldTimeOutlined, SaveOutlined } from '@ant-design/icons';
import { FormItem, Radio } from 'common/antd';
import { FormButtons } from 'components';
import { combine } from 'stores';
import { options } from './config';

export const Settlement = combine<{ price?: number; temporarySave: () => void }>(({ price, temporarySave }) => {
  return (
    <>
      <FormItem label="办理服务费（含邮件）">
        <span className="error" style={{ fontSize: '1.5em', fontWeight: 700 }}>
          {price || 0}元
        </span>
      </FormItem>
      <FormItem label="支付方式">
        <Radio options={options.payType} value="预付款支付" />
      </FormItem>
      <FormButtons formItemProps={{ wrapperCol: { offset: 7, span: 16 } }}>
        <Space>
          <Button type="primary" icon={<FieldTimeOutlined />} ghost onClick={temporarySave}>
            临时保存
          </Button>
          <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
            提交
          </Button>
        </Space>
      </FormButtons>
    </>
  );
});
