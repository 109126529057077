import { useMemo } from 'react';
import { DatePicker, Button } from 'antd';
import { FormItem, Select } from 'common/antd';
import { useTable, PageTable, FormSearch, SelectMortgagee } from 'components';
import { createColumns } from './config';
import { options } from 'pages/unsecured/application/config';
import { mortgage } from 'apis';

const toValues = ({ mortgagee, time, ...data }: any) => {
  const [beginDate, endDate] = time || [];
  Object.assign(data, {
    outletsName: mortgagee?.[1],
    beginDate: beginDate?.format('YYYY-MM-DD HH:mm:ss'),
    endDate: endDate?.format('YYYY-MM-DD HH:mm:ss'),
  });
  return data;
};

const Page = () => {
  const { states, setData, pageTableProps, formSearchProps, del, DelButton } = useTable({
    onList: async ({ current, pageSize, search }) => {
      const res = await mortgage.temporary.getList({ pageNum: current, pageSize, ...toValues(search) });
      res.ok && setData(res.data.list || [], res.data.count);
    },
    onDel: async (ids) => {
      for await (let id of ids) {
        const res = await mortgage.temporary.del(id);
        if (!res.ok) return true;
      }
      return true;
    },
  });

  // 生成表格配置数据
  const columns = useMemo(() => createColumns({ del }), []);

  return (
    <PageTable
      {...pageTableProps}
      columns={columns}
      extra={
        <>
          <DelButton />
          <Button type="primary" onClick={() => mortgage.temporary.export()(toValues(states.search))}>
            导出
          </Button>
        </>
      }
    >
      <FormSearch {...formSearchProps}>
        <FormItem label="登记时间" name="time">
          <DatePicker.RangePicker showTime />
        </FormItem>
        <FormItem label="机构" name="mortgagee" placeholder select>
          <SelectMortgagee />
        </FormItem>
        <FormItem label="号牌种类" name="carType" placeholder select>
          <Select options={options.carType} style={{ width: 140 }} />
        </FormItem>
        <FormItem label="申请编号" name="id" placeholder />
        <FormItem label="车牌号码" name="carNumber" placeholder />
        <FormItem label="车架号" name="carCode" placeholder />
        <FormItem label="车主姓名" name="carOwner" placeholder />
        <FormItem label="手机号码" name="phone" placeholder />
      </FormSearch>
    </PageTable>
  );
};

export default Page;
