import { FC } from 'react';
import { DatePicker } from 'antd';
import { FormItem, InputNumber } from 'common/antd';
import { UploadPicture } from 'components';

export const ContractInformation: FC<{ itemProps: (...names: string[]) => any }> = ({ itemProps }) => {
  const mortageFee = itemProps('mortageFee');

  return (
    <>
      <FormItem label="抵押合同原件" name="contractPic" required="请上传结清证明原件" {...itemProps('contractPic')}>
        <UploadPicture />
      </FormItem>
      <FormItem label="抵押合同号" name="contractNo" placeholder required maxLength={50} {...itemProps('contractNo')} />
      <FormItem label="抵押金额" formItemProps={{ required: true, ...mortageFee }} {...mortageFee}>
        <FormItem
          name="mortageFee"
          placeholder="请输入抵押金额"
          required
          formItemProps={{ noStyle: true }}
          {...mortageFee}
        >
          <InputNumber min={0} max={9999999.99} precision={2} style={{ width: 180 }} />
        </FormItem>
        <span style={{ marginLeft: 10 }}>元</span>
      </FormItem>
      <FormItem label="抵押日期" name="mortageDate" required select {...itemProps('mortageDate')}>
        <DatePicker />
      </FormItem>
      <FormItem label="合同结清日期" name="contractExpireDate" required select {...itemProps('contractExpireDate')}>
        <DatePicker />
      </FormItem>
    </>
  );
};
