import { useState } from 'react';
import { message } from 'antd';
import { getFiles } from 'seasoning/es/file-tool';
import { upload } from 'common/request';
import { gallerySign } from 'apis';
import { extname } from 'path';

/**
 * 上传文件到OSS
 */
export const uploadOSS = async (file: File) => {
  const sign = await gallerySign(extname(file.name).substr(1));
  if (sign.ok) {
    const { OSSAccessKeyId, signature, OSS_HOST, key, policy, imageUrl } = sign.data;
    const data = { key, policy, OSSAccessKeyId, success_action_status: '200', signature, file };
    const res = await upload(OSS_HOST, data, '上传到图片库', { headers: {} });
    if (res.ok) return imageUrl;
  }
};

export type useUploadPictureOptions = {
  multiple?: boolean;
  maxSize?: number;
  onUpload: (url: string) => void;
};

/**
 * 上传图片 Hooks
 */
export const useUploadPicture = ({ multiple, onUpload, maxSize = 1024 }: useUploadPictureOptions) => {
  const [loading, setLoading] = useState(false);

  const upload = async () => {
    if (loading) return;
    try {
      const files = await getFiles({
        multiple,
        maxSize,
        extnames: ['jpg', 'png', 'jpeg'],
        onFile: () => setLoading(true),
      });
      for await (let file of files) {
        const url = await uploadOSS(file);
        url && onUpload(url);
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      message.error( '仅支持上传后缀名为：jpg、png、jpeg的文件');
    }
  };

  return { loading, upload };
};
