import { FC } from 'react';
import { Switch } from 'antd';
import { regular } from 'seasoning/es/verify';
import { FormItem, InputNumber } from 'common/antd';
import { FormModal, useInfoModal } from 'components';
import { user } from 'apis';

export const WarningSettings: FC<{ data: any; onOk: () => void }> = ({ data: defaultData, onOk }) => {
  const { formModalProps, edit } = useInfoModal({
    messageText: '余额告警设置成功',
    onSubmit: async ({ alarmType, ...values }: any) => {
      const { userSub, sourceServiceId } = defaultData;
      const res = await user.balance.alarmSettings({ userSub, sourceServiceId, alarmType: +alarmType, ...values });
      res.ok && onOk();
      return res.ok;
    },
  });

  return (
    <>
      <span className="edit pointer" onClick={() => edit(defaultData)}>
        告警设置
      </span>
      <FormModal title="余额告警设置" {...formModalProps}>
        <FormItem
          label="预警手机"
          name="alarmPhone"
          placeholder="预警手机号一"
          required="请输入预警手机"
          maxLength={11}
          rules={[{ pattern: regular.phone, message: '请输入正确的手机号' }]}
        />
        <FormItem
          label="预警手机"
          name="alarmPhoneBak"
          placeholder="预警手机号二"
          maxLength={11}
          rules={[{ pattern: regular.phone, message: '请输入正确的手机号' }]}
        />
        <FormItem
          label="预警邮箱"
          name="alarmEmail"
          placeholder
          rules={[{ type: 'email', message: '请输入合理的邮箱地址' }]}
        />
        <FormItem label="预警金额" formItemProps={{ required: true }}>
          <FormItem name="alarmAmount" placeholder="请输入预警金额" required formItemProps={{ noStyle: true }}>
            <InputNumber min={0} max={9999999.99} precision={2} style={{ width: 200 }} />
          </FormItem>
          <span style={{ marginLeft: 10 }}>元</span>
        </FormItem>
        <FormItem label="预警状态" name="alarmType" valuePropName="checked">
          <Switch />
        </FormItem>
      </FormModal>
    </>
  );
};
