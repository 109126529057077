import dayjs from 'dayjs';
export const options = {
  carType: [
    { value: '02', label: '小型汽车' },
    { value: '01', label: '大型汽车' },
    { value: '52', label: '小型新能源车辆' },
    { value: '51', label: '大型新能源车辆' },
    { value: '15', label: '挂车' },
    { value: '16', label: '教练汽车' },
  ],
  subjectType: [
    { value: 2, label: '个人' },
    { value: 1, label: '单位/企业' },
  ],
  inLocalAccount: [
    { value: 1, label: '本地户籍' },
    { value: 0, label: '非本地户籍' },
  ],
  synchronize: [{ value: 1, label: '邮寄与揽收信息一致' }],
  deliverType: [
    { value: '上门收件', label: '上门收件' },
    { value: '自送', label: '自送' },
  ],
  payType: [{ value: '预付款支付', label: '预付款支付' }],
};

export const regexp = {
  idNo: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
};

export const toTemporaryForm = ({ requestJson, ...data }: any) => {
  Object.assign(data, JSON.parse(requestJson));
  return toForm(data);
};

export const toForm = ({
  mortgageeName,
  outletsName,
  settleDate,
  mortageDate,
  contractExpireDate,
  otherPic1,
  otherPic2,
  otherPic3,
  otherPic4,
  otherPic5,
  recipientList,
  ...data
}: any) => {
  Object.assign(data, {
    mortgagee: mortgageeName ? [mortgageeName, outletsName] : [],
    settleDate: settleDate && dayjs(settleDate),
    mortageDate: mortageDate && dayjs(mortageDate),
    contractExpireDate: contractExpireDate && dayjs(contractExpireDate),
    otherPic: [otherPic1, otherPic2, otherPic3, otherPic4, otherPic5].filter(Boolean),
    recipientList: (recipientList || [])
      .sort((x: any, y: any) => y.type - x.type)
      .map(({ provinceName, cityName, areaName, ...i }: any) => {
        return { addressInfo: [provinceName, cityName, areaName].filter(Boolean), ...i };
      }),
  });
  return data;
};

export const toOrderValues = ({
  // 过滤字段
  mortgageeAddress,
  synchronize,
  // 有效字段
  carCode,
  carNumber,
  carNumberPrefix,
  carType,
  mortgageeId,
  recipientList,
  deliverType,
  temporarySaveId,
  // 附加字段信息
  ...extension
}: any) => {
  return {
    carCode,
    carNumber,
    carNumberPrefix,
    carType,
    mortgageeId,
    recipientList,
    deliverType,
    temporarySaveId,
    extension,
  };
};

export const toTemporaryValues = ({
  // 固定字段
  id,
  carCode,
  carNumber,
  carOwner,
  carType,
  deliverType,
  mortgageeName,
  outletsName,
  payType,
  phone,
  contractNo,
  // 拓展字段
  ...requestJson
}: any) => {
  return {
    id,
    carCode,
    carNumber,
    carOwner,
    carType,
    deliverType,
    mortgageeName,
    outletsName,
    payType,
    phone,
    contractNo,
    requestJson: JSON.stringify(requestJson),
  };
};

export const toValues = ({
  mortgagee,
  settleDate,
  mortageDate,
  contractExpireDate,
  otherPic,
  recipientList,
  ...values
}: any) => {
  const [mortgageeName, outletsName] = mortgagee || [];
  const [otherPic1, otherPic2, otherPic3, otherPic4, otherPic5] = otherPic || [];
  Object.assign(values, {
    payType: '预付款支付',
    mortgageeName,
    outletsName,
    settleDate: settleDate?.format('YYYY-MM-DD'),
    mortageDate: mortageDate?.format('YYYY-MM-DD'),
    contractExpireDate: contractExpireDate?.format('YYYY-MM-DD'),
    otherPic1,
    otherPic2,
    otherPic3,
    otherPic4,
    otherPic5,
    recipientList: (recipientList || []).map(({ addressInfo = [], ...info }: any, k: number) => {
      const [provinceName, cityName, areaName] = addressInfo;
      return { provinceName, cityName, areaName, ...info, type: +!k + 1 };
    }),
  });
  return values;
};

/**
 * 判断是否输入值
 */
export const isNotNull = ({ mortgageeAddress, recipientList = [], ...data }: any) => {
  return isNotNullObject(data) || recipientList.some(isNotNullObject);
};

const isNotNullObject = (data: any) => {
  return Object.values(data).some((i) => i !== undefined && i !== null && i !== '');
};

/**
 * 排序专用name
 */
export const names = [
  'mortgageeAddress',
  'mortgagee',
  'carType',
  'carNumber',
  'carCode',
  'subjectType',
  'carOwner',
  'phone',
  'idNo',
  'inLocalAccount',
  'idCardBackPic',
  'secondary',
  'idCardFrontPic',
  'LicensePic',
  'scenePic',
  'registerFirstPic',
  'registerSecondPic',
  'carProxyPic',
  'ApplyPic',
  'contractPic',
  'contractNo',
  'mortageFee',
  'mortageDate',
  'contractExpireDate',
  'settlePic',
  'settleDate',
  'managerPic',
  'recipientList',
];
