import { message } from 'antd';
import { FormItem, Password } from 'common/antd';
import { PageForm, useInfo } from 'components';
import { combine } from 'stores';
import { user } from 'apis';

const rule = {
  pattern: /^[A-Za-z0-9-_]{4,50}$/,
  message: '请输入4-50位字母、数字、下划线、“-”的组合字符',
};

const Page = combine(({ stores }) => {
  const { username, phone } = stores.user.info;
  const { setLoading, pageFormProps, formRef } = useInfo({
    onFinish: async (values: any) => {
      setLoading('正在保存');
      const res = await user.edit({ username, phone, ...values });
      if (!res.ok) return setLoading(false);
      setLoading(false);
      message.success('修改密码成功');
    },
  });

  return (
    <PageForm {...pageFormProps} onBack={false}>
      <FormItem label="账号">{username || '未登录'}</FormItem>
      <FormItem label="手机号">{phone || '未登录'}</FormItem>
      <FormItem label="旧密码" name="oldPwd" placeholder required maxLength={50} rules={[rule]}>
        <Password />
      </FormItem>
      <FormItem label="新密码" name="newPwd" placeholder required maxLength={50} rules={[rule]}>
        <Password />
      </FormItem>
      <FormItem
        label="确认密码"
        name="confirmPwd"
        placeholder
        required
        maxLength={50}
        rules={[rule]}
        validator={(v) => {
          const newPwd = formRef.current!.form.getFieldValue('newPwd');
          if (newPwd !== v) return '两次输入密码不一致';
        }}
      >
        <Password />
      </FormItem>
    </PageForm>
  );
});

export default Page;
