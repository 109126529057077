import { FC } from 'react';
import { regular } from 'seasoning/es/verify';
import { FormItem, Checkbox, Radio } from 'common/antd';
import { FormButtons, SelectArea } from 'components';
import { options } from './config';

export const MailingInformation: FC<{ synchronize?: number[] }> = ({ synchronize }) => {
  const disabled = synchronize?.[0];

  return (
    <>
      <FormItem
        disabled={disabled}
        label="收件人姓名"
        name={['recipientList', 1, 'name']}
        placeholder
        required
        maxLength={8}
        rules={[{ pattern: regular.chineseName, message: '请输入中文姓名' }]}
      />
      <FormItem
        disabled={disabled}
        label="收件人手机"
        name={['recipientList', 1, 'phone']}
        placeholder
        required
        maxLength={11}
        rules={[{ pattern: regular.phone, message: '请输入正确的手机号' }]}
      />
      <FormItem disabled={disabled} label="收件人省市区" name={['recipientList', 1, 'addressInfo']} required select>
        <SelectArea valueKey="name" />
      </FormItem>
      <FormItem
        disabled={disabled}
        label="详细地址"
        name={['recipientList', 1, 'address']}
        placeholder="请输入收件人详细地址"
        required
      />
      <FormButtons name="synchronize" formItemProps={{ wrapperCol: { offset: 7, span: 16 } }}>
        <Checkbox options={options.synchronize} />
      </FormButtons>
      <FormItem label="资料交接方式" name="deliverType" required select>
        <Radio options={options.deliverType} />
      </FormItem>
    </>
  );
};
