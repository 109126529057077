import { FC } from 'react';
import { regular } from 'seasoning/es/verify';
import { FormItem, Radio } from 'common/antd';
import { CarNumber } from 'components';
import { options } from './config';

export const MotorVehicleInformation: FC = () => {
  return (
    <>
      <FormItem label="号牌类型" name="carType" placeholder required select>
        <Radio options={options.carType} />
      </FormItem>
      <FormItem
        label="车牌号码"
        name="carNumber"
        placeholder
        required
        rules={[{ pattern: regular.carNumber, message: '请输入正确的车牌号' }]}
      >
        <CarNumber />
      </FormItem>
      <FormItem label="车辆识别代码" name="carCode" placeholder required maxLength={17} />
    </>
  );
};
