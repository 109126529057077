import { message } from 'antd';
import { get } from 'common/request';
import { TRequestReturn } from 'seasoning/es/request/types';
import { blobDownload } from 'seasoning/es/file-tool';

/**
 * 缓存区域
 */
const caches: any = {};

/**
 * 请求中
 */
const requestLoadings: { [key: string]: boolean } = {};

/**
 * 创建缓存请求
 */
export const cacheRequest = <T extends TRequestReturn>(key: string, request: (...args: any[]) => Promise<T>) => {
  const fun = async (...args: any[]) => {
    if (requestLoadings[key]) {
      // 暂停一秒
      await new Promise((r) => setTimeout(r, 500));
      // 继续请求
      const res: T = await fun(...args);
      return res;
    }
    requestLoadings[key] = true;
    const res: T = caches[key] || (await request(...args));
    if (res.ok) caches[key] = res;
    requestLoadings[key] = false;
    return res;
  };
  return fun;
};

/**
 * 创建下载
 */
export const createDownload = (fileName: string, url = `/usercenter/${fileName}`, defaultData?: any) => {
  return async (data?: any) => {
    message.loading('正在获取文件', 0);
    const res = await get(url, { ...defaultData, ...data }, `下载：${fileName}`, { responseType: 'blob' });
    message.destroy();
    if (res.ok) {
      if (/json/.test(res.data.response.headers.get('Content-Type'))) {
        message.error('下载失败');
        return;
      }
      message.success('下载成功');
      const blob = await res.data.blob;
      blobDownload(blob, fileName);
    }
  };
};
