import { FC } from 'react';
import { regular } from 'seasoning/es/verify';
import { FormItem, Radio } from 'common/antd';
import { UploadPicture } from 'components';
import { options, regexp } from './config';

export const MortgageInformation: FC<{ subjectType: number; itemProps: (...names: string[]) => any }> = ({
  subjectType,
  itemProps,
}) => {
  return (
    <>
      <FormItem label="抵押人性质" name="subjectType" placeholder required select {...itemProps('subjectType')}>
        <Radio options={options.subjectType} />
      </FormItem>
      <FormItem
        label="机动车所有人"
        name="carOwner"
        placeholder
        required
        maxLength={30}
        rules={[{ pattern: /^(?:[\u4e00-\u9fa5·]{2,30})$/, message: '请输入中文姓名' }]}
        {...itemProps('carOwner')}
      />
      <FormItem
        label="联系电话"
        name="phone"
        placeholder
        required
        maxLength={11}
        rules={[{ pattern: regular.phone, message: '请输入正确的手机号' }]}
        {...itemProps('phone')}
      />
      {subjectType === 2 && (
        <>
          <FormItem
            label="证件号码"
            name="idNo"
            placeholder
            required
            maxLength={18}
            rules={[{ pattern: regular.idCard, message: '请输入正确的身份证号码' }]}
            {...itemProps('idNo')}
          />

          <FormItem label="户籍情况" name="inLocalAccount" placeholder required select {...itemProps('inLocalAccount')}>
            <Radio options={options.inLocalAccount} />
          </FormItem>
          <FormItem
            label="身份证原件"
            formItemProps={{ required: true }}
            {...itemProps('idCardBackPic', 'idCardFrontPic')}
          >
            <div className="align-top">
              <div>
                <FormItem
                  name="idCardBackPic"
                  required="请上传身份证原件（人像面）"
                  formItemProps={{ noStyle: true }}
                  {...itemProps('idCardBackPic')}
                >
                  <UploadPicture />
                </FormItem>
                <div className="secondary">人像面</div>
              </div>
              <div style={{ marginLeft: 30 }}>
                <FormItem
                  name="idCardFrontPic"
                  required="请上传身份证原件（国徽面）"
                  formItemProps={{ noStyle: true }}
                  {...itemProps('idCardFrontPic')}
                >
                  <UploadPicture />
                </FormItem>
                <div className="secondary">国徽面</div>
              </div>
            </div>
          </FormItem>
        </>
      )}
      {subjectType === 1 && (
        <>
          <FormItem
            label="组织机构代码证"
            name="idNo"
            placeholder
            required
            maxLength={18}
            rules={[{ pattern: regexp.idNo, message: '请输入正确的组织机构代码证' }]}
            formItemProps={{ extra: '统一社会信用代码' }}
            {...itemProps('idNo')}
          />
          <FormItem label="营业执照原件" name="LicensePic" required="请上传营业执照原件" {...itemProps('LicensePic')}>
            <UploadPicture />
          </FormItem>
        </>
      )}
      <FormItem label="抵押人现场照片" name="scenePic" required="请上传抵押人现场照片" {...itemProps('scenePic')}>
        <UploadPicture />
      </FormItem>
    </>
  );
};
