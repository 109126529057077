import { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { combine } from 'stores';
import { user } from 'apis';

const num = 10;
let timeout: NodeJS.Timeout | null;
const clear = () => {
  if (timeout) {
    clearInterval(timeout);
    timeout = null;
  }
};

export const Captcha = combine(({ stores }) => {
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(num);
  const isSend = time !== num;

  const countdown = () => {
    setTime((time) => {
      const newTime = time - 1;
      if (!newTime) return num;
      timeout = setTimeout(countdown, 1000);
      return newTime;
    });
  };

  const getCaptcha = async () => {
    if (loading || isSend) return;
    setLoading(true);
    const res = await user.payPassword.getCaptcha(stores.user.info.phone);
    setLoading(false);
    if (res.ok) {
      message.success('验证码发送成功');
      clear();
      countdown();
    }
  };

  useEffect(() => {
    return clear;
  }, []);

  return (
    <Button type="primary" disabled={loading || isSend} style={{ width: 140, marginLeft: 10 }} onClick={getCaptcha}>
      {isSend ? `重新发送 ${time}s` : loading ? '正在发送' : '获取验证码'}
    </Button>
  );
});
