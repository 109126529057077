import { Descriptions, Image, Space } from 'antd';
import { FileWordOutlined } from '@ant-design/icons';
import { urlDownload } from 'seasoning/es/file-tool';
import { PageDetails, useDetails } from 'components';
import { combinePage } from 'stores';
import { mortgage } from 'apis';
import { options as orderOptions } from './config';
import { options } from 'pages/unsecured/application/config';
import style from 'pages/unsecured/application/style.module.less';

const { Item } = Descriptions;

type TParams = { orderId: string };

const Page = combinePage<TParams>(({ stores, match }) => {
  const { orderId } = match.params;
  const { data, setData, setLoading, pageDetailsProps } = useDetails({
    getData: async () => {
      // 从订单列表进入
      setLoading('加载中');
      // 加载
      const mortgagee = await mortgage.getMortgagee();
      if (mortgagee.ok) {
        stores.unsecured.setMortgagee(mortgagee.data);
      }
      const res = await mortgage.order.details(orderId);
      setLoading(false);
      if (res.ok) {
        setData(res.data);
      }
    },
  });
  // 详情别名
  const i = data.items?.[0]?.itemExtension || {};

  const renders: {
    column?: number;
    title: string;
    children: {
      label?: React.ReactNode;
      text?: React.ReactNode;
      img?: string;
      file?: string;
      fileName?: string;
      span?: number;
      render?: () => React.ReactNode;
    }[];
  }[] = [
    {
      title: '基本信息',
      children: [
        { label: '申请编号', text: data.orderId },
        { label: '申请时间', text: data.createTime },
        { label: '抵押权人', text: i.mortgageeName && `${i.mortgageeName}/${i.outletsName}` },
      ],
    },
    {
      title: '机动车信息',
      children: [
        { label: '号牌类型', text: options.carType.find((j) => j.value === i.carType)?.label },
        { label: '车牌号码', text: i.carNumber },
        { label: '车辆识别代码', text: i.carCode },
      ],
    },
    {
      column: 2,
      title: '抵押人信息',
      children: [
        { label: '抵押人性质', text: options.subjectType.find((j) => j.value === +i.subjectType)?.label },
        { label: '机动车所有人', text: i.carOwner },
        { label: '联系电话', text: i.phone },
        ...(+i.subjectType === 1
          ? [
              { label: +i.subjectType === 2 ? '证件号码' : '组织机构代码证', text: i.idNo },
              { label: '营业执照原件', img: 'LicensePic', text: '无', span: 2 },
            ]
          : [
              {
                label: '户籍情况',
                text: options.inLocalAccount.find((j) => j.value === +i.inLocalAccount)?.label,
                span: 3,
              },
              { label: '身份证原件（人像面）', img: 'idCardBackPic' },
              { label: '身份证原件（国徽面）', img: 'idCardFrontPic' },
            ]),
        { label: '抵押人现场照片', img: 'scenePic', span: 2 },
      ],
    },
    {
      column: 2,
      title: '登记证书信息',
      children: [
        { label: '登记证书原件（1-2页）', img: 'registerFirstPic' },
        { label: '登记证书原件（3-4页）', img: 'registerSecondPic' },
      ],
    },
    {
      column: 2,
      title: '委托材料',
      children: [
        { label: '机动车所有人委托书原件', img: 'carProxyPic' },
        { label: '机动车解/抵押申请表', img: 'ApplyPic' },
      ],
    },
    {
      column: 2,
      title: '合同信息',
      children: [
        { label: '抵押合同号', text: i.contractNo },
        { label: '抵押金额', text: i.mortageFee },
        { label: '抵押日期', text: i.mortageDate },
        { label: '合同结清日期', text: i.contractExpireDate },
        { label: '抵押合同原件', img: 'contractPic' },
      ],
    },
    {
      column: 2,
      title: '经办人信息',
      children: [
        { label: '经办人现场图片', img: 'managerPic' },
        {
          label: '代理人信息',
          render: () => {
            if (!i.outletsName) {
              return <div className={`secondary ${style.mortgageeInfo}`}>未选择抵押权人</div>;
            }
            const data = stores.unsecured.getMortgagee(i.outletsName);
            return (
              <div className={`secondary ${style.mortgageeInfo}`}>
                <p>抵押权人代理人名称：{data.managerName}</p>
                <p>抵押权人代理人身份证号码：{data.managerId}</p>
                <p>抵押权人代理人联系电话：{data.managerPhone}</p>
                <Space align="start" size="large" style={{ marginTop: 5 }}>
                  <div className="column-left">
                    <span>身份证(人像)</span>
                    <Image src={data.managerIdCardBackPic} className={style.img} />
                  </div>
                  <div className="column-left">
                    <span>身份证(国徽)</span>
                    <Image src={data.managerIdCardFrontPic} className={style.img} />
                  </div>
                </Space>
              </div>
            );
          },
        },
      ],
    },
    {
      column: 2,
      title: '其他信息',
      children: [
        {
          span: 2,
          label: '其他图片',
          render: () => {
            const otherPic = ['otherPic1', 'otherPic2', 'otherPic3', 'otherPic4', 'otherPic5']
              .map((img) => data.requirementList?.find((i: any) => i.requirementType === img)?.imgUrl)
              .filter(Boolean);

            return (
              <Image.PreviewGroup>
                {otherPic.map((i) => (
                  <Image key={i} src={i} style={{ width: 80, height: 80 }} />
                ))}
              </Image.PreviewGroup>
            );
          },
        },
        { label: '其他注释', text: i.otherRemark },
      ],
    },
    {
      column: 2,
      title: '寄递信息',
      children: (() => {
        // 寄递信息
        const [c = {}, m = {}] = (data.recipientInfo || []).sort((x: any, y: any) => y.type - x.type);
        return [
          {
            label: '揽收信息',
            text: <span className="error">预审核通过后，办理机构通知揽投员上门收取原件的寄递信息</span>,
          },
          { label: '邮寄信息', text: <span className="error">业务受理成功后，原件回寄给车主的寄递信息</span> },
          { label: '代办人姓名', text: c.name },
          { label: '收件人姓名', text: m.name },
          { label: '代办人手机', text: c.phone },
          { label: '收件人手机', text: m.phone },
          { label: '代办人省市区', text: [c.provinceName, c.cityName, c.areaName].filter(Boolean).join('') },
          { label: '收件人省市区', text: [m.provinceName, m.cityName, m.areaName].filter(Boolean).join('') },
          { label: '详细地址', text: c.address },
          { label: '详细地址', text: m.address },
          { label: '支付方式', text: i.payType },
          { label: '资料交接方式', text: i.deliverType },
        ];
      })(),
    },
  ];

  return (
    <PageDetails
      {...pageDetailsProps}
      headerChildren={
        <span style={{ fontWeight: 700, fontSize: '16px' }}>
          订单状态: {orderOptions.orderStatus.find((j) => j.value === +data.orderStatus)?.label}
        </span>
      }
    >
      <div style={{ height: 30 }} />
      {renders.map(({ title, children, ...props }, k) => (
        <Descriptions
          key={k}
          title={
            <p style={{ background: '#eee', padding: 5 }}>
              {k + 1}.{title}
            </p>
          }
          {...props}
          style={{ marginBottom: 20 }}
        >
          {children.map(({ render, label, img, file, fileName, text, ...props }, k) => {
            let child = render?.();
            if (img) {
              const src = data.requirementList?.find((i: any) => i.requirementType === img)?.imgUrl;
              if (src) child = <Image src={src} style={{ width: 160, height: 80 }} />;
            } else if (file) {
              const src = data.requirementList?.find((i: any) => i.requirementType === file)?.imgUrl;
              child = (
                <FileWordOutlined
                  style={{ fontSize: 55 }}
                  className="pointer"
                  onClick={() => {
                    urlDownload(src, fileName || '文件');
                  }}
                />
              );
            }

            return (
              <Item key={k} label={label} {...props}>
                {child || text}
              </Item>
            );
          })}
        </Descriptions>
      ))}
      <div style={{ height: 30 }} />
    </PageDetails>
  );
});

export default Page;
