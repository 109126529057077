import { makeAutoObservable, when, runInAction } from 'mobx';
import { message } from 'antd';
import { menuData } from 'common/menu';
import { modalConfirm } from 'common/antd';
import { user } from 'apis';

/**
 * 用户
 */
export default class User {
  /**
   * 是否登录
   */
  isLogin = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 登陆状态监听
   */
  onLogin = (bind: boolean | (() => void)) => {
    if (typeof bind === 'boolean') {
      this.isLogin = bind;
    } else {
      return when(() => this.isLogin, bind);
    }
  };

  access_token = localStorage.access_token;
  username = localStorage.username;
  info: any = {};
  menu: any[] = menuData;
  get headers() {
    return {
      Authorization: `Bearer ${this.access_token}`,
      userType: this.username,
    };
  }

  /**
   * 显示登录页面
   */
  get showLogin() {
    return !this.isLogin && !this.access_token;
  }

  /**
   * 权限过滤菜单
   */
  permissionFilter() {
    this.menu = menuData;
  }

  /**
   * 登录
   */
  login = async (data: any) => {
    message.success('登录成功');
    this.permissionFilter();
    const { access_token, username } = data;
    Object.assign(localStorage, { access_token, username });
    Object.assign(this, { access_token, username });
    this.getInfo();
  };

  /**
   * 退出登录
   */
  logout = () => {
    Object.assign(this, { access_token: '', username: '', info: {}, isLogin: false });
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
  };

  /**
   * 注销对话框
   */
  logoutConfirm = () => {
    modalConfirm({
      title: '确定要退出登录吗？',
      okButtonProps: { danger: true },
      onOk: this.logout,
    });
  };

  /**
   * 自动登录
   */
  autoLogin = async () => {
    if (!this.access_token) return;
    message.loading('正在加载', 0);
    await this.getInfo();
    message.destroy();
  };

  /**
   * 获取用户信息
   */
  getInfo = async () => {
    const res = await user.getInfo(this.headers);
    if (res.ok) {
      this.permissionFilter();
      runInAction(() => {
        this.info = res.data;
        this.isLogin = true;
      });
    }
  };
}
