import { FC } from 'react';
import { FormItem } from 'common/antd';
import { UploadPicture } from 'components';

export const CertificateInformation: FC<{ itemProps: (...names: string[]) => any }> = ({ itemProps }) => {
  return (
    <>
      <FormItem
        label="登记证书原件"
        formItemProps={{ required: true }}
        {...itemProps('registerFirstPic', 'registerSecondPic')}
      >
        <div className="align-top">
          <div>
            <FormItem
              name="registerFirstPic"
              required="请上传登记证书原件（第1-2页）"
              formItemProps={{ noStyle: true }}
              {...itemProps('registerFirstPic')}
            >
              <UploadPicture />
            </FormItem>
            <div className="secondary">第1-2页</div>
          </div>
          <div style={{ marginLeft: 30 }}>
            <FormItem
              name="registerSecondPic"
              required="请上传登记证书原件（第3-4页）"
              formItemProps={{ noStyle: true }}
              {...itemProps('registerSecondPic')}
            >
              <UploadPicture />
            </FormItem>
            <div className="secondary">第3-4页</div>
          </div>
        </div>
      </FormItem>
    </>
  );
};
