import { useEffect } from 'react';
import { Button, ConfigProvider } from 'antd';
import { UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { useStates } from 'seasoning/es/hooks';
import { Transition } from 'seasoning';
import { useForm, Form, FormItem, Password } from 'common/antd';
import { defaultTitle, setTitle } from 'common/router';
import { combine } from 'stores';
import { auth } from 'apis';
import style from './style.module.less';

const Page = combine(({ stores }) => {
  const { form } = useForm();
  const { states, setStates } = useStates({ loading: false as string | boolean, src: '', sessionKey: '' });
  const { loading, src, sessionKey } = states;

  const getImg = async () => {
    const res = await auth.getVerifyCode();
    if (!res.ok) return true;
    const { imageBase64, sessionKey } = res.data;
    const src = `data:image/png;base64,${imageBase64}`;
    form.resetFields(['verifyCode']);
    setStates({ src, sessionKey });
  };

  /**
   * 提交数据
   */
  const onFinish = async (values: any) => {
    setStates({ loading: '正在登录' });
    const res = await auth.login({ ...values, sessionKey });
    if (res.ok) {
      stores.user.login(res.data);
    } else {
      getImg();
    }
    setStates({ loading: false });
  };

  useEffect(() => {
    setTitle(`登录 - ${defaultTitle}`);
    getImg();
  }, []);

  return (
    <div className={`fill column-center ${style.login}`}>
      <img src={require('images/logo.svg').default} alt="" />
      <p>{defaultTitle}</p>
      <ConfigProvider componentSize="large">
        <Form className={style.form} form={form} onFinish={onFinish}>
          <FormItem
            name="username"
            prefix={<UserOutlined />}
            placeholder="请输入用户名"
            required
            maxLength={50}
            rules={[
              {
                pattern: /^[A-Za-z0-9-_]{4,50}$/,
                message: '请输入4-50位字母、数字、下划线、“-”的组合字符',
              },
            ]}
          />
          <FormItem
            name="password"
            prefix={<LockOutlined />}
            placeholder="请输入密码"
            required
            maxLength={50}
            rules={[
              {
                pattern: /^[A-Za-z0-9-_]{4,50}$/,
                message: '请输入4-50位字母、数字、下划线、“-”的组合字符',
              },
            ]}
          >
            <Password />
          </FormItem>
          <FormItem>
            <div className="align-center">
              <FormItem
                name="verifyCode"
                prefix={<SafetyCertificateOutlined />}
                placeholder="请输入验证码"
                required
                maxLength={4}
                validator={(v: string) => {
                  if (v?.length !== 4) return '请输入4位验证码';
                }}
                formItemProps={{ noStyle: true }}
              />
              <div className={`pointer ${style.verifyCode}`}>
                <Transition name="top">
                  <img className="fill" key={sessionKey} src={src} alt="" onClick={getImg} />
                </Transition>
              </div>
            </div>
          </FormItem>
          <Button className={style.button} htmlType="submit" type="primary" block loading={!!loading}>
            {loading || '登录'}
          </Button>
        </Form>
      </ConfigProvider>
    </div>
  );
});

export default Page;
