import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Action } from 'components';
import { commonColumns } from '../temporarySave/config';
import { printData, printTable } from './print';
import { tracks } from './action';

/**
 * 生成表格配置数据
 */
export const createColumns = ({ status }: any) => {
  const columns: ColumnsType = [
    ...commonColumns[0],
    {
      title: '申请编号',
      dataIndex: 'orderId',
      width: 200,
      render: (v) => (
        <Link className="edit" to={`/mortgage/order/details/${status}/${v}`}>
          {v}
        </Link>
      ),
    },
    ...commonColumns[1],
    { title: '一程邮件号', dataIndex: 'expressNbr', width: 150 },
    { title: '二程邮件号', dataIndex: 'returnExpressNbr', width: 150 },
    { title: '支付方式', dataIndex: 'payType', width: 150 },
    { title: '操作人', dataIndex: 'operator', width: 120 },
    { title: '订单状态', dataIndex: 'orderStatusName', width: 90, fixed: 'right' },
    {
      title: '办理状态',
      key: 'confirmStatus',
      width: 90,
      fixed: 'right',
      render: ({ orderId }) => {
        return (
          <span className="edit pointer" onClick={() => tracks(orderId)}>
            查看
          </span>
        );
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 110,
      fixed: 'right',
      render: ({ orderId }) => (
        <Action>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className="edit" onClick={() => printData(orderId)}>
                  打印相关资料
                </Menu.Item>
                <Menu.Item className="edit" onClick={() => printTable(orderId)}>
                  打印申请表
                </Menu.Item>
              </Menu>
            }
          >
            <span className="edit pointer">
              打印资料 <DownOutlined />
            </span>
          </Dropdown>
        </Action>
      ),
    },
  ];

  return columns;
};

export const options = {
  orderStatus: [
    // { value: 1, label: '未支付' },
    { value: 2, label: '已支付' },
    { value: 7, label: '处理中' },
    { value: 8, label: '已完成' },
    { value: 4, label: '已退单' },
  ],
  confirmStatus: [
    { value: -1, label: '代办失败' },
    { value: 0, label: '预审核不通过' },
    { value: 1, label: '未审核' },
    { value: 2, label: '预审核通过' },
    { value: 3, label: '揽收中' },
    { value: 4, label: '办理中' },
    { value: 5, label: '代办成功' },
    { value: 6, label: '已办结' },
  ],
};
