import { FC } from 'react';
import { regular } from 'seasoning/es/verify';
import { FormItem } from 'common/antd';
import { SelectArea } from 'components';

export const CollectInformation: FC = () => {
  return (
    <>
      <FormItem
        label="代办人姓名"
        name={['recipientList', 0, 'name']}
        placeholder
        required
        maxLength={8}
        rules={[{ pattern: regular.chineseName, message: '请输入中文姓名' }]}
      />
      <FormItem
        label="代办人手机"
        name={['recipientList', 0, 'phone']}
        placeholder
        required
        maxLength={11}
        rules={[{ pattern: regular.phone, message: '请输入正确的手机号' }]}
      />
      <FormItem label="代办人省市区" name={['recipientList', 0, 'addressInfo']} required select>
        <SelectArea valueKey="name" />
      </FormItem>
      <FormItem label="详细地址" name={['recipientList', 0, 'address']} placeholder="请输入代办人详细地址" required />
    </>
  );
};
