import { SettingOutlined } from '@ant-design/icons';
import { TMenuNavData } from './antd';

const orderMenuItem = (type: string, title: string, confirmStatus?: any) => {
  const status = confirmStatus ? JSON.stringify(confirmStatus) : 'all';
  return {
    path: `/${type}/order/${status}`,
    pagePath: `/${type}/order`,
    title,
    children: [
      {
        path: `/${type}/order/details/${status}/:orderId`,
        pagePath: `/${type}/order/details`,
        title: '订单详情',
        hidden: true,
      },
    ],
  };
};

/**
 * 左侧导航数据和标题数据
 * path 是相对 /src/pages 引用文件的路径
 */
export const menuData: TMenuNavData[] = [
  {
    icon: <SettingOutlined />,
    title: '抵押',
    children: [
      { path: '/mortgage/application', title: '业务申请' },
      {
        path: '/mortgage/temporarySave',
        title: '临时保存列表',
        children: [
          {
            path: '/mortgage/application/temporarySave/:temporarySaveId',
            pagePath: '/mortgage/application',
            title: '业务申请',
            hidden: true,
          },
        ],
      },
      orderMenuItem('mortgage', '全部订单管理'),
    ],
  },
  {
    icon: <SettingOutlined />,
    title: '解除抵押',
    children: [
      { path: '/unsecured/application', title: '业务申请' },
      {
        path: '/unsecured/temporarySave',
        title: '临时保存列表',
        children: [
          {
            path: '/unsecured/application/temporarySave/:temporarySaveId',
            pagePath: '/unsecured/application',
            title: '业务申请',
            hidden: true,
          },
        ],
      },
      orderMenuItem('unsecured', '全部订单管理'),
    ],
  },
  {
    icon: <SettingOutlined />,
    title: '账号管理',
    children: [
      { path: '/account/settings', title: '账号设置' },
      { path: '/account/changePassword', title: '修改密码' },
      // { path: '/account/payPassword', title: '支付密码' },
      // { path: '/account/balance', title: '账户余额' },
    ],
  },
];
