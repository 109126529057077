import { FC } from 'react';
import { FormItem, TextArea } from 'common/antd';
import { UploadPictureGroup } from 'components';

export const OtherInformation: FC<{ itemProps: (...names: string[]) => any }> = ({ itemProps }) => {
  return (
    <>
      <FormItem label="其他图片" name="otherPic" required="请上传图片" {...itemProps('otherPic1')}>
        <UploadPictureGroup max={5} />
      </FormItem>
      <FormItem label="其他注释" name="otherRemark" required="请输入注释内容" placeholder {...itemProps('otherRemark')}>
        <TextArea />
      </FormItem>
    </>
  );
};
