import { message } from 'antd';
import { unsecured } from 'apis';
import { installScriptOne } from 'seasoning/es/dom-tool';
import { getImg, imgToBase64 } from 'seasoning/es/img-tool';
import { options } from 'pages/unsecured/application/config';
import { stores } from 'stores';
// import dayjs from 'dayjs';

const inits = {
  pdfmake: installScriptOne('/pdfmake.min.js'),
  vfs_fonts: installScriptOne('/vfs_fonts.js'),
};

const init = async (orderId: string) => {
  message.loading('加载文件生成工具', 0);
  await inits.pdfmake();
  await inits.vfs_fonts();

  window.pdfMake.fonts = {
    方正姚体: { normal: 'FZYTK.TTF', bold: 'FZYTK.TTF', italics: 'FZYTK.TTF', bolditalics: 'FZYTK.TTF' },
  };

  message.destroy();

  message.loading('加载详细数据', 0);
  const res = await unsecured.order.details(orderId);
  message.destroy();

  return res;
};

/**
 * 打印相关资料
 */
export const printData = async (orderId: string) => {
  const res = await init(orderId);
  if (!res.ok) return;

  message.loading('生成文件', 0);

  const getSrc = (name: string) => {
    return res.data.requirementList.find((i: any) => i.requirementType === name)?.imgUrl;
  };

  const img = async (name: string, props?: any) => {
    const src = getSrc(name);
    if (!src) return;
    const img = await getImg(src);
    const image = await imgToBase64(img);
    return { image, ...props };
  };

  const oneImg = async (_text: string, name: string) => {
    return await img(name, { fit: [500, 700], pageBreak: 'before' });
  };

  const twoImg = async (_text: string, names: string[]) => {
    return [
      await img(names[0], { fit: [500, 350], margin: [0, 0, 0, 50], pageBreak: 'before' }),
      await img(names[1], { fit: [500, 350] }),
    ];
  };

  const content = [
    await oneImg('抵押人现场照片', 'scenePic'),
    await twoImg('身份证原件', ['idCardBackPic', 'idCardFrontPic']),
    await oneImg('登记证书原件（第1-2页）', 'registerFirstPic'),
    await oneImg('登记证书原件（第3-4页）', 'registerSecondPic'),
    await oneImg('机动车所有人委托书原件', 'carProxyPic'),
    await oneImg('机动车解/抵押申请表', 'ApplyPic'),
    await oneImg('抵押合同照片', 'contractPic'),
    await oneImg('经办人图片', 'managerPic'),
    await oneImg('其他图片1', 'otherPic1'),
    await oneImg('其他图片2', 'otherPic2'),
    await oneImg('其他图片3', 'otherPic3'),
    await oneImg('其他图片4', 'otherPic4'),
    await oneImg('其他图片5', 'otherPic5'),
  ]
    .flat()
    .filter(Boolean);

  content[0].pageBreak = undefined;

  window.pdfMake
    .createPdf({ defaultStyle: { fontSize: 24, font: '方正姚体', alignment: 'center' }, content })
    // .download(`相关资料_${dayjs().format('YYYYMMDDHHmm')}.pdf`);
    // .open();
    .print();
  message.destroy();
};

/**
 * 打印申请表
 */
export const printTable = async (orderId: string) => {
  const res = await init(orderId);
  if (!res.ok) return;

  message.loading('生成文件', 0);

  const { data } = res;

  // 详情别名
  const i = data.items?.[0]?.itemExtension || {};

  // 寄递信息
  const [_c = {}, _m = {}] = (data.recipientInfo || []).sort((x: any, y: any) => y.type - x.type);

  // 获取抵押权人信息
  const mortgagee = await unsecured.getMortgagee();
  if (mortgagee.ok) stores.unsecured.setMortgagee(mortgagee.data);
  const mortgageeInfo = stores.unsecured.getMortgagee(i.outletsName);

  // 列
  const c = (text?: any, props?: any) => {
    const { c, r, ...options } = props || {};
    const res: any[] = [{ text, margin: 3, colSpan: c, rowSpan: r, ...options }];
    return c > 1 ? res.concat(Array(c - 1).fill('')) : res;
  };

  // 行
  const r = (...cs: any[]) => {
    return cs.flat();
  };

  const content = [
    {
      text: '机动车抵押登记/质押备案申请表',
      style: { fontSize: 24 },
      margin: [0, 0, 0, 30],
    },
    {
      table: {
        headerRows: 1,
        widths: [15, 55, '*', 70, 70, 70, 110],
        body: [
          r(
            c('号牌种类', { c: 2 }),
            c(options.carType.find((j) => j.value === i.carType)?.label, { c: 3 }),
            c('号码号牌'),
            c(i.carNumber)
          ),
          r(
            c('申请事项', { c: 2 }),
            c('□ 抵押登记 ■ 解除抵押登记 □ 质押 □ 解除质押', { c: 4 }),
            c('机动车所有人及代理人对申请资料的真实有效性负责。', {
              r: 2,
              style: { bold: true, alignment: 'left' },
            })
          ),
          r(c('机动车所有人\n姓名/名称', { c: 2 }), c(i.carOwner, { c: 4, margin: [0, 10, 0, 0] }), ''),

          // ********************************* 机动车所有人的代理人 ********************************* //
          r(
            c('机动车所有人的代理人', { r: 3 }),
            c('姓名/名称'),
            c(_m.name, { c: 4 }),
            c(
              [
                { text: '机动车所有人（代理人）\n签字：\n\n\n\n\n\n\n', style: { alignment: 'left' } },
                { text: '年\t\t月\t\t日', style: { alignment: 'right' } },
              ],
              { r: 3 }
            )
          ),
          r('', c('邮政编码'), c(''), c('联系电话'), c(_m.phone, { c: 2 }), ''),
          r(
            '',
            c('邮寄地址'),
            c([_m.provinceName, _m.cityName, _m.areaName, _m.address].filter(Boolean).join(''), {
              c: 4,
              style: { alignment: 'left' },
            }),
            ''
          ),
          // ********************************* 机动车所有人的代理人 ********************************* //

          // ********************************* 抵押权人/典当行 ********************************* //
          r(
            c('抵押权人/典当行', { r: 3 }),
            c('姓名/名称'),
            c(mortgageeInfo.managerName, { c: 4 }),
            c('抵押权人/典当行及代理人对申请资料的真实性负责。', { r: 2, style: { bold: true, alignment: 'left' } })
          ),
          r(
            '',
            c('邮政编码', { margin: [0, 5, 0, 0] }),
            c('', { margin: [0, 5, 0, 0] }),
            c('联系电话', { margin: [0, 5, 0, 0] }),
            c(mortgageeInfo.managerPhone, { c: 2, margin: [0, 5, 0, 0] }),
            ''
          ),
          r(
            '',
            c('邮寄地址'),
            c(mortgageeInfo.detailAddress, { c: 4, style: { alignment: 'left' } }),
            c([
              { text: '抵押权人/典当行\n签字：\n\n\n', style: { alignment: 'left' } },
              { text: '年\t\t月\t\t日', style: { alignment: 'right' } },
            ])
          ),
          // ********************************* 抵押权人/典当行 ********************************* //

          // ********************************* 抵押权人/典当行的代理人 ********************************* //
          r(
            c('抵押权人/典当行的代理人', { r: 3 }),
            c('姓名/名称'),
            c(_c.name, { c: 4 }),
            c(
              [
                { text: '代理人签字：\n\n\n\n\n\n\n\n\n\n\n', style: { alignment: 'left' } },
                { text: '年\t\t月\t\t日', style: { alignment: 'right' } },
              ],
              { r: 3 }
            )
          ),
          r('', c('邮政编码'), c(''), c('联系电话'), c(_c.phone, { c: 2 }), ''),
          r(
            '',
            c('邮寄地址'),
            c([_c.provinceName, _c.cityName, _c.areaName, _c.address].filter(Boolean).join(''), {
              c: 4,
              style: { alignment: 'left' },
            }),
            ''
          ),
          // ********************************* 抵押权人/典当行的代理人 ********************************* //

          // ********************************* 领证方式 ********************************* //
          r(
            c('领证方式', { c: 2, r: 2, margin: [0, 16, 0, 0] }),
            c('□ 邮政速递 □ 回办证机关领取 （请在选项前的“□”内打“√”）', { c: 5 })
          ),
          r('', '', c('★特别提示：如选择邮政速递方式领证，请按照如下要求详细填写，以免发生误差。', { c: 5 })),
          // ********************************* 领证方式 ********************************* //

          // ********************************* 邮寄地址 ********************************* //
          r(
            c('邮寄地址', { c: 2, r: 2, margin: [0, 16, 0, 0] }),
            c([_m.provinceName, _m.cityName, _m.areaName].filter(Boolean).join(''), {
              c: 2,
              style: { alignment: 'left' },
            }),
            c('收件人'),
            c('邮政编码'),
            c('联系电话（手机）')
          ),
          r('', '', c(_m.address, { c: 2, style: { alignment: 'left' } }), c(_m.name), c(''), c(_m.phone)),
          // ********************************* 邮寄地址 ********************************* //
        ],
      },
    },
  ];

  window.pdfMake
    .createPdf({
      defaultStyle: { font: '方正姚体', alignment: 'center', fontSize: 11, lineHeight: 1.2 },
      content,
    })
    // .download(`相关资料_${dayjs().format('YYYYMMDDHHmm')}.pdf`);
    // .open();
    .print();
  message.destroy();
};
