import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Action } from 'components';
import { options } from 'pages/unsecured/application/config';

export const commonColumns: ColumnsType[] = [
  [
    { title: '金融机构', dataIndex: 'mortgageeName', width: 120, ellipsis: true },
    { title: '归属机构', dataIndex: 'outletsName', width: 120, ellipsis: true },
  ],
  [
    { title: '车牌号码', dataIndex: 'carNumber', width: 120 },
    {
      title: '号牌种类',
      dataIndex: 'carType',
      width: 130,
      render: (v) => options.carType.find((i) => i.value === v)?.label,
    },
    { title: '车架号', dataIndex: 'carCode', width: 200 },
    { title: '机动车所有人', dataIndex: 'carOwner', width: 200, ellipsis: true },
    { title: '手机号码', dataIndex: 'phone', width: 130 },
    { title: '抵押合同号', dataIndex: 'contractNo', width: 200, ellipsis: true },
    { title: '登记时间', dataIndex: 'createTime', width: 180 },
    { title: '资料交接', dataIndex: 'deliverType', width: 100 },
  ],
];

/**
 * 生成表格配置数据
 */
export const createColumns = ({ del }: any) => {
  const columns: ColumnsType = [
    ...commonColumns[0],
    { title: '临时编号', dataIndex: 'id', width: 100 },
    ...commonColumns[1],
    { title: '支付方式', dataIndex: 'payType', width: 120 },
    { title: '操作人', dataIndex: 'operator', width: 120 },
    {
      title: '操作',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (data) => (
        <Action>
          <Link className="edit" to={`/mortgage/application/temporarySave/${data.id}`}>
            提交申请
          </Link>
          <span className="delete pointer" onClick={() => del([data.id])}>
            删除
          </span>
        </Action>
      ),
    },
  ];

  return columns;
};
