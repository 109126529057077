import { FC, useState } from 'react';
import { Tabs, Button, Typography, Modal } from 'antd';
import { PayCircleOutlined } from '@ant-design/icons';
import { regular } from 'seasoning/es/verify';
import { useForm, InputNumber } from 'common/antd';

import { FormItem } from 'common/antd';
import { FormLayout, FormButtons } from 'components';
import { user } from 'apis';
import style from './style.module.less';

const { TabPane } = Tabs;

export const Recharge: FC = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="在线充值" key="1">
        <OnlineRecharge />
      </TabPane>
      <TabPane tab="银行卡转账" key="2">
        <BankCardTransfer />
      </TabPane>
    </Tabs>
  );
};

const maxWidth = 750;

const OnlineRecharge: FC = () => {
  const [loading, setLoading] = useState(false);
  const { form } = useForm();
  const cols: [number, number] = [3, 14];
  const [offset, span] = cols;

  const onFinish = async ({ money }: any) => {
    setLoading(true);
    const res = await user.balance.recharge(money);
    setLoading(false);
    if (res.ok) {
      window.location.href = res.data.result.paySign;
    }
  };

  return (
    <FormLayout name="pageForm" {...{ form, maxWidth, cols }} onFinish={onFinish}>
      <FormItem label="充值金额" formItemProps={{ required: true }}>
        <FormItem
          name="money"
          placeholder="请输入充值金额"
          required
          validator={(v) => {
            if (v <= 0) return '充值金额必须大于0';
          }}
          formItemProps={{ noStyle: true }}
        >
          <InputNumber min={0} max={9999999.99} precision={2} style={{ width: 200 }} />
        </FormItem>
        <span style={{ marginLeft: 10 }}>元</span>
      </FormItem>
      <FormItem label="支付方式">
        <div className={`center ${style.alipay}`}>
          <img src={require('images/pay_alipay.png').default} alt="" />
          <div>
            <p>支付宝</p>
            <p>ALIPAY</p>
          </div>
        </div>
      </FormItem>
      <FormButtons formItemProps={{ wrapperCol: { offset, span } }}>
        <Button type="primary" icon={<PayCircleOutlined />} htmlType="submit" loading={loading}>
          去支付
        </Button>
      </FormButtons>
    </FormLayout>
  );
};

const { Title, Text } = Typography;

const BankCardTransfer: FC = () => {
  const [loading, setLoading] = useState(false);
  const { form } = useForm();
  const cols: [number, number] = [5, 14];
  const [offset, span] = cols;

  const onFinish = async (values: any) => {
    setLoading(true);
    const res = await user.balance.bankCardTransfer(values);
    setLoading(false);
    if (res.ok) {
      Modal.success({
        title: '提交成功',
        content: '充值申请已提交，需管理员审核，请耐心等待',
      });
    }
  };

  return (
    <div className={style.bankCardTransfer}>
      <div>
        <Title level={5}>第一步</Title>
        <p>请您通过网银转账，或者自行到银行进行汇款，汇款账号如下：</p>
        <div className={style.bankCard}>
          <div>
            <span className={style.label}>开户名称</span>
            <Text copyable>广州时光大数据科技有限公司</Text>
          </div>
          <div>
            <span className={style.label}>开户银行</span>
            <Text copyable>中国招商银行</Text>
          </div>
          <div>
            <span className={style.label}>开户支行</span>
            <Text copyable>招商银行广州科技园支行</Text>
          </div>
          <div>
            <span className={style.label}>账号</span>
            <Text copyable={{ text: '120913732610101' }}>1209 1373 2610 101</Text>
          </div>
        </div>
        <Title level={5}>温馨提示：</Title>
        <p>1、受银行处理时间影响，采用线下汇款方式到账会有延迟。</p>
        <p>2、具体到账时间以银行的实际到账时间为准。</p>
        <p>3、财务确认收到款后直接匹配到您的车行易数据平台账户上。</p>
      </div>
      <div>
        <Title level={5}>第二步</Title>
        <p style={{ marginBottom: 15 }}>转账后填写转账记录，财务确认后充值成功：</p>
        <FormLayout name="pageForm" {...{ form, maxWidth, cols }} onFinish={onFinish}>
          <FormItem label="转账金额" formItemProps={{ required: true }}>
            <FormItem
              name="rechargeMoney"
              placeholder="请输入转账金额"
              required
              validator={(v) => {
                if (v <= 0) return '转账金额必须大于0';
              }}
              formItemProps={{ noStyle: true }}
            >
              <InputNumber min={0} max={9999999.99} precision={2} style={{ width: 180 }} />
            </FormItem>
            <span style={{ marginLeft: 10 }}>元</span>
          </FormItem>
          <FormItem
            label="开户名称"
            name="accountName"
            placeholder
            required
            maxLength={20}
            rules={[{ pattern: /^[\u4e00-\u9fa5]{1,20}$/, message: '请输入正确的开户名称' }]}
          />
          <FormItem
            label="转账账号"
            name="payAccount"
            placeholder
            required
            maxLength={20}
            rules={[{ pattern: /^\d{1,20}$/, message: '请输入正确的转账账号' }]}
          />
          <FormItem
            label="手机号码"
            name="telephone"
            placeholder
            required
            maxLength={11}
            rules={[{ pattern: regular.phone, message: '请输入正确的手机号码' }]}
          />
          <FormButtons formItemProps={{ wrapperCol: { offset, span } }}>
            <Button type="primary" icon={<PayCircleOutlined />} htmlType="submit" loading={loading}>
              提交
            </Button>
          </FormButtons>
        </FormLayout>
      </div>
    </div>
  );
};
