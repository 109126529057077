import { message } from 'antd';
import { FormItem } from 'common/antd';
import { PageForm, useInfo } from 'components';
import { combine } from 'stores';
import { user } from 'apis';

const Page = combine(({ stores }) => {
  const { info, getInfo } = stores.user;
  const { username, phone } = info;
  const { setLoading, pageFormProps } = useInfo({
    onFinish: async (values: any) => {
      setLoading('正在保存');
      const res = await user.edit({ username, phone, ...values });
      if (!res.ok) return setLoading(false);
      setLoading(false);
      message.loading('刷新用户信息', 0);
      await getInfo();
      message.destroy();
    },
  });

  return (
    <PageForm {...pageFormProps} onBack={false} initialValues={info} key={username}>
      <FormItem label="账号">{username || '未登录'}</FormItem>
      <FormItem label="手机号">{phone || '未登录'}</FormItem>
      <FormItem
        label="昵称"
        name="nickName"
        placeholder
        required
        maxLength={20}
        rules={[{ pattern: /^[0-9A-Za-z\u4e00-\u9fa5]{2,20}$/, message: '请输入2-20位的昵称,支持中文' }]}
      />
      <FormItem label="邮箱" name="email" placeholder rules={[{ type: 'email', message: '请输入合理的邮箱地址' }]} />
    </PageForm>
  );
});

export default Page;
