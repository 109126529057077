import { FC } from 'react';
import { FormItem } from 'common/antd';
import { UploadPicture } from 'components';
import { unsecured } from 'apis';

export const CommissionedMaterials: FC<{ itemProps: (...names: string[]) => any }> = ({ itemProps }) => {

  return (
    <>
      <FormItem
        label="机动车所有人委托书原件"
        name="carProxyPic"
        required="请上传机动车所有人委托书原件"
        formItemProps={{
          extra: (
            <span className="primary pointer" onClick={() => unsecured.download.carProxyPic()}>
              点击下载委托书
            </span>
          ),
        }}
        {...itemProps('carProxyPic')}
      >
        <UploadPicture  />
      </FormItem>
      <FormItem
        label="机动车解/抵押申请表"
        name="ApplyPic"
        required="请上传机动车解/抵押申请表"
        formItemProps={{
          extra: (
            <span className="primary pointer" onClick={() => unsecured.download.ApplyPic()}>
              点击下载申请表
            </span>
          ),
        }}
        {...itemProps('ApplyPic')}
      >
        <UploadPicture  />
      </FormItem>
    </>
  );
};
