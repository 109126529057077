import { get, post, put } from 'common/request';
import { Application } from 'seasoning/es/request/types';
import { Encrypt } from 'utils';
import dayjs from 'dayjs';
import qs from 'qs';
import { cacheRequest, createDownload } from './tool';

/**
 * 鉴权
 */
export const auth = {
  getVerifyCode: () => get('/verifyCode/create/v2', {}, '获取图形验证码'),
  login: async ({ sessionKey, password, ...data }: any) => {
    password = await Encrypt(password);
    return post('/auth/oauth/token', { password, ...data, grant_type: 'password', scope: 'server' }, '登录', {
      headers: { sessionKey, 'Content-Type': Application.Form, Authorization: 'Basic ZGF0YW9uZTpkYXRhb25l' },
    });
  },
};

/**
 * 获取图片库签名
 */
export const gallerySign = (format: string) => get('/usercenter/messages/gallerySign', { format }, '获取图片库签名');

export const user = {
  getInfo: (headers: any) =>
    get('/usercenter/user/simple', {}, '获取帐号信息', {
      noMessage: true,
      headers: { 'Content-Type': Application.Form, ...headers },
    }),
  edit: async ({ oldPwd, newPwd, ...data }: any) => {
    if (oldPwd) oldPwd = await Encrypt(oldPwd);
    if (newPwd) newPwd = await Encrypt(newPwd);
    return put('/usercenter/user/editSimple', { oldPwd, newPwd, ...data }, '账号设置');
  },
  payPassword: {
    getCaptcha: (phone: string) =>
      get(`/usercenter/userRegister/resetPayPwdCode?${qs.stringify({ phone })}`, {}, '获取支付密码重置验证码'),
    edit: async ({ payPwd, ...data }: any) => {
      payPwd = await Encrypt(payPwd);
      return put('/usercenter/user/payPwd', { payPwd, ...data }, '修改支付密码');
    },
  },
  balance: {
    getList: () => get('/usercenter/useSub/account-detail-list', {}, '获取账户余额'),
    alarmSettings: (data: any) => put('/usercenter/useSub/alarm-set-up', data, '告警设置'),
    recharge: (money: string) =>
      get(`/shop/api/shop/order/recharge?${qs.stringify({ money, payType: 29 })}`, {}, '充值金额'),
    bankCardTransfer: (data: any) => post('/usercenter/recharge-apply/save', data, '银行卡转账提交'),
  },
  getArea: cacheRequest('area', async () => get('/usercenter/area', {}, '获取地区级联信息', { timeout: 10000 })),
};

/**
 * 解抵押
 */
export const unsecured = {
  download: {
    carProxyPic: createDownload('机动车业务授权委托书.doc'),
    ApplyPic: createDownload('机动车解除抵押申请表.doc'),
  },
  getMortgagee: cacheRequest('mortgagee', () =>
    post('/usercenter/mortgage/mortgagee/findByMerchantName', {}, '获取抵押权人列表')
  ),
  temporary: {
    save: (data: any) => post('/usercenter/mortgage/temporarySave/save', { ...data, mortgageType: 0 }, '临时保存订单'),
    getList: (data: any) =>
      get('/usercenter/mortgage/temporarySave/page', { ...data, mortgageType: 0 }, '获取临时保存列表'),
    details: (id: string) => get('/usercenter/mortgage/temporarySave/get', { id }, '获取临时保存订单详情'),
    del: (id: string) => get('/usercenter/mortgage/temporarySave/delete', { id }, '删除临时保存订单'),
    export: () =>
      createDownload(
        `解除抵押_临时保存订单_${dayjs().format('YYYYMMDDHHmm')}.xlsx`,
        '/usercenter/mortgage/temporarySave/export',
        { mortgageType: 0 }
      ),
  },
  order: {
    confirm: (data: any) => post('/usercenter/mortgage/order/confirm', { ...data, mortgageType: 0 }, '试算'),
    create: (data: any) => post('/usercenter/mortgage/order/placeOrder', { ...data, mortgageType: 0 }, '创建订单'),
    getList: (data: any) => get('/usercenter/mortgage/order/orderList', { ...data, mortgageType: 0 }, '获取订单列表'),
    details: (orderId: string) => get('/usercenter/mortgage/order/getOrder', { orderId }, '获取订单详情'),
    export: (fileName: string) =>
      createDownload(
        `解除抵押_${fileName}_${dayjs().format('YYYYMMDDHHmm')}.xlsx`,
        '/usercenter/mortgage/order/export',
        { mortgageType: 0 }
      ),
    getTracks: (orderId: string) => get('/usercenter/mortgage/order/getOrderTracks', { orderId }, '获取订单轨迹'),
    cancel: (itemId: string) =>
      post(`/usercenter/mortgage/order/cancelOrder?${qs.stringify({ itemId })}`, {}, '取消订单'),
  },
};

/**
 * 抵押
 */
export const mortgage = {
  getMortgagee: unsecured.getMortgagee,
  temporary: {
    save: (data: any) => post('/usercenter/mortgage/temporarySave/save', { ...data, mortgageType: 1 }, '临时保存订单'),
    getList: (data: any) =>
      get('/usercenter/mortgage/temporarySave/page', { ...data, mortgageType: 1 }, '获取临时保存列表'),
    details: (id: string) => get('/usercenter/mortgage/temporarySave/get', { id }, '获取临时保存订单详情'),
    del: (id: string) => get('/usercenter/mortgage/temporarySave/delete', { id }, '删除临时保存订单'),
    export: () =>
      createDownload(
        `抵押_临时保存订单_${dayjs().format('YYYYMMDDHHmm')}.xlsx`,
        '/usercenter/mortgage/temporarySave/export',
        { mortgageType: 1 }
      ),
  },
  order: {
    confirm: (data: any) => post('/usercenter/mortgage/order/confirm', { ...data, mortgageType: 1 }, '试算'),
    create: (data: any) => post('/usercenter/mortgage/order/placeOrder', { ...data, mortgageType: 1 }, '创建订单'),
    getList: (data: any) => get('/usercenter/mortgage/order/orderList', { ...data, mortgageType: 1 }, '获取订单列表'),
    details: (orderId: string) => get('/usercenter/mortgage/order/getOrder', { orderId }, '获取订单详情'),
    export: (fileName: string) =>
      createDownload(
        `抵押_${fileName}_${dayjs().format('YYYYMMDDHHmm')}.xlsx`,
        '/usercenter/mortgage/order/export',
        { mortgageType: 1 }
      ),
    getTracks: (orderId: string) => get('/usercenter/mortgage/order/getOrderTracks', { orderId }, '获取订单轨迹'),
    cancel: (itemId: string) =>
      post(`/usercenter/mortgage/order/cancelOrder?${qs.stringify({ itemId })}`, {}, '取消订单'),
  },
};
