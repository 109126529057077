import { makeAutoObservable } from 'mobx';

/**
 * 解抵押
 */
export default class Unsecured {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 抵押权人列表
   */
  mortgagee: any[] = [];
  setMortgagee = (mortgagee: any[]) => {
    this.mortgagee = mortgagee;
  };
  getMortgagee = (outletsName: string) => {
    return this.mortgagee.find((i) => i.outletsName === outletsName) || {};
  };

  /**
   * 地区数据
   */
  area: any[] = [];
  setArea = (area: any[]) => {
    this.area = area;
  };
  getCarNumberPrefix = (area: string[], areaList = this.area) => {
    for (let i of areaList) {
      if (i.name === area[0]) {
        for (let j of i.cityList) {
          if (i.name === j.name) return i.abbreviation;
          if (j.name === area[1]) return i.abbreviation + j.carPrefix;
        }
      }
    }
    return '';
  };
}
