import { useMemo } from 'react';
import { DatePicker, Button, message } from 'antd';
import { FormItem, Select } from 'common/antd';
import { useTable, PageTable, FormSearch, SelectMortgagee } from 'components';
import { createColumns, options } from './config';
import { options as applicationOptions } from 'pages/unsecured/application/config';
import { combinePage } from 'stores';
import { unsecured } from 'apis';
import { throttle } from 'lodash';
import dayjs from 'dayjs';

const toValues = ({ mortgagee, time, ...data }: any) => {
  const [begin, end] = time || [];
  Object.assign(data, {
    outletsName: mortgagee?.[1],
    begin: begin?.format('YYYY-MM-DD HH:mm:ss'),
    end: end?.format('YYYY-MM-DD HH:mm:ss'),
  });
  return data;
};

const Page = combinePage(({ match }) => {
  // 获取URL筛选数据
  const status = match.url.split('/')[3];

  // 生成表格配置数据
  const initialValues = useMemo(() => {
    return {
      time: [dayjs().subtract(30, 'd'), dayjs()],
    };
  }, []);

  const { fileName = '全部', confirmStatus }: any = status === 'all' ? {} : JSON.parse(status);
  const { states, setData, pageTableProps, formSearchProps, getList } = useTable({
    defaultStates: { search: initialValues },
    onList: async ({ current, pageSize, search }) => {
      const res = await unsecured.order.getList({
        pageNum: current,
        pageSize,
        confirmStatus: confirmStatus?.join(','),
        ...toValues(search),
      });
      if (res.ok) {
        const { list, count } = res.data;
        setData(list || [], count || list.length);
      }
    },
  });

  // 生成表格配置数据
  const columns = useMemo(() => createColumns({ status, getList }), []);

  const exportFun = throttle(() => {
    unsecured.order.export(fileName)({ pageNum: 1, pageSize: 10000, ...toValues(states.search) });
  }, 3000);

  return (
    <PageTable
      {...pageTableProps}
      columns={columns}
      extra={
        <>
          <Button type="primary" onClick={exportFun}>
            导出
          </Button>
        </>
      }
      rowKey="orderId"
    >
      <FormSearch
        {...formSearchProps}
        initialValues={initialValues}
        onFinish={(search) => {
          if (!search.time) {
            message.error('请选择登记时间');
            return;
          }
          formSearchProps.onFinish(search);
        }}
      >
        <FormItem label="登记时间" name="time">
          <DatePicker.RangePicker showTime />
        </FormItem>
        <FormItem label="机构" name="mortgagee" placeholder select>
          <SelectMortgagee />
        </FormItem>
        <FormItem label="号牌种类" name="carType" placeholder select>
          <Select options={applicationOptions.carType} style={{ width: 140 }} />
        </FormItem>
        <FormItem label="订单状态" name="orderStatus" placeholder select>
          <Select options={options.orderStatus} />
        </FormItem>
        {status === 'all' && (
          <>
            {/*<FormItem label="办理状态" name="confirmStatus" placeholder select>
              <Select options={options.confirmStatus} style={{ width: 140 }} />
            </FormItem>*/}
            <FormItem label="一程邮件号" name="expressNbr" placeholder />
            <FormItem label="二程邮件号" name="returnExpressNbr" placeholder />
          </>
        )}
        <FormItem label="申请编号" name="orderId" placeholder />
        <FormItem label="车牌号码" name="carNumber" placeholder />
        <FormItem label="车架号" name="carCode" placeholder />
        <FormItem label="车主姓名" name="carOwner" placeholder />
        <FormItem label="手机号码" name="phone" placeholder />
      </FormSearch>
    </PageTable>
  );
});

export default Page;
