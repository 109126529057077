import { ColumnsType } from 'antd/es/table';
import { Action } from 'components';
import { WarningSettings } from './warningSettings';
import { Badge } from 'antd';

/**
 * 生成表格配置数据
 */
export const createColumns = ({ getData }: any) => {
  const columns: ColumnsType = [
    { title: '用途', dataIndex: 'walletName', width: 160 },
    { title: '服务名称 ', dataIndex: 'serviceName', width: 120 },
    { title: '余额(元)', dataIndex: 'balance', width: 140 },
    { title: '冻结金额(元) ', dataIndex: 'frozenAmount', width: 120 },
    { title: '预警金额(元)', dataIndex: 'alarmAmount', width: 120 },
    { title: '手机号码', dataIndex: 'alarmPhone', width: 140 },
    { title: '预警邮箱', dataIndex: 'alarmEmail', width: 200 },
    {
      title: '余额告警',
      dataIndex: 'alarmType',
      width: 80,
      fixed: 'right',
      render: (v: any) => (v ? <Badge status="success" text="开启" /> : <Badge status="default" text="关闭" />),
    },
    {
      title: '操作',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (v: any) => (
        <Action>
          <WarningSettings data={v} onOk={getData} />
        </Action>
      ),
    },
  ];

  return columns;
};
