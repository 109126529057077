import { Space, Image } from 'antd';
import { FormItem } from 'common/antd';
import { UploadPicture } from 'components';
import { combine } from 'stores';
import style from './style.module.less';

export const ManagerInformation = combine<{ itemProps: (...names: string[]) => any }>(({ stores, itemProps }) => {
  return (
    <>
      <FormItem label="经办人现场图片" formItemProps={{ required: true }} {...itemProps('managerPic')}>
        <div className="align-top">
          <FormItem
            name="managerPic"
            required="请上传经办人现场图片"
            formItemProps={{ noStyle: true }}
            {...itemProps('managerPic')}
          >
            <UploadPicture />
          </FormItem>
          <FormItem
            formItemProps={{
              shouldUpdate: (prevValues, curValues) => prevValues.mortgagee?.[1] !== curValues.mortgagee?.[1],
            }}
          >
            {({ getFieldValue }) => {
              const mortgagee = getFieldValue('mortgagee');
              const outletsName = mortgagee?.[1];
              if (!outletsName) {
                return (
                  <div className={`secondary ${style.mortgageeInfo}`} style={{ marginLeft: 50 }}>
                    请选择抵押权人
                  </div>
                );
              }
              const data = stores.unsecured.getMortgagee(outletsName);
              return (
                <div className={`secondary ${style.mortgageeInfo}`} style={{ marginLeft: 50 }}>
                  <p>抵押权人代理人名称：{data.managerName}</p>
                  <p>抵押权人代理人身份证号码：{data.managerId}</p>
                  <p>抵押权人代理人联系电话：{data.managerPhone}</p>
                  <Space align="start" size="large" style={{ marginTop: 5 }}>
                    <div className="column-left">
                      <span>身份证(人像)</span>
                      <Image src={data.managerIdCardBackPic} className={style.img} />
                    </div>
                    <div className="column-left">
                      <span>身份证(国徽)</span>
                      <Image src={data.managerIdCardFrontPic} className={style.img} />
                    </div>
                  </Space>
                </div>
              );
            }}
          </FormItem>
        </div>
      </FormItem>
    </>
  );
});
