import { FC } from 'react';
import { DatePicker } from 'antd';
import { FormItem } from 'common/antd';
import { UploadPicture } from 'components';

export const ClearInformation: FC<{ itemProps: (...names: string[]) => any }> = ({ itemProps }) => {
  return (
    <>
      <FormItem label="结清证明原件" name="settlePic" required="请上传结清证明原件" {...itemProps('settlePic')}>
        <UploadPicture />
      </FormItem>
      <FormItem label="结清证明日期" name="settleDate" required select {...itemProps('settleDate')}>
        <DatePicker />
      </FormItem>
    </>
  );
};
