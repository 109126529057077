import { Steps, message, Modal } from 'antd';
import { regular } from 'seasoning/es/verify';
import { PageForm, useInfo } from 'components';
import { combinePage } from 'stores';
import { mortgage, user } from 'apis';
import { debounce } from 'lodash';
import {
  toTemporaryForm,
  toValues,
  toOrderValues,
  toTemporaryValues,
  isNotNull,
  names,
} from 'pages/unsecured/application/config';
import style from 'pages/unsecured/application/style.module.less';

import { BasicInformation } from 'pages/unsecured/application/basicInformation';
import { MotorVehicleInformation } from 'pages/unsecured/application/motorVehicleInformation';
import { MortgageInformation } from 'pages/unsecured/application/mortgageInformation';
import { CertificateInformation } from 'pages/unsecured/application/certificateInformation';
import { CommissionedMaterials } from 'pages/unsecured/application/commissionedMaterials';
import { ContractInformation } from 'pages/unsecured/application/contractInformation';
import { ManagerInformation } from 'pages/unsecured/application/managerInformation';
import { OtherInformation } from 'pages/unsecured/application/otherInformation';
import { CollectInformation } from 'pages/unsecured/application/collectInformation';
import { MailingInformation } from 'pages/unsecured/application/mailingInformation';
import { Settlement } from 'pages/unsecured/application/settlement';

const { Step } = Steps;

type TParams = { temporarySaveId?: string; orderId?: string };

const Page = combinePage<TParams>(({ stores, match, history }) => {
  const { temporarySaveId, orderId } = match.params;
  const onBack = !!(temporarySaveId || orderId);

  const { states, setStates, setLoading, pageFormProps, formRef } = useInfo({
    defaultData: {
      mortgageeAddress: [44],
    },
    getData: async () => {
      if (temporarySaveId) {
        // 从临时保存列表进入
        setLoading('加载中');
        const res = await mortgage.temporary.details(temporarySaveId);
        setLoading(false);
        if (res.ok) {
          const data = toTemporaryForm(res.data);
          const { mortgageeAddress, subjectType = 2, synchronize = [] } = data;
          setStates({ mortgageeAddress, subjectType, synchronize });
          formRef.current?.form.setFieldsValue(data);
          const area = await user.getArea();
          if (area.ok) trialCalculation(data, area.data);
        }
      } else if (orderId) {
        // 从订单列表进入
        setLoading('加载中');
        const res = await mortgage.order.details(orderId);
        setLoading(false);
        if (res.ok) {
        }
      } else {
        await user.getArea();
        getRecipientList();
      }
    },
    onFinish: async (values: any) => {
      setLoading('正在保存');
      const RECIPIENTLIST = JSON.stringify(values.recipientList);
      values = toValues(values);
      values = toOrderValues(values);
      values.carNumberPrefix = states.carNumberPrefix;
      values.mortgageeId = stores.unsecured.getMortgagee(values.extension.outletsName)?.id;
      // 删除临时保存数据
      values.temporarySaveId = temporarySaveId;
      const res = await mortgage.order.create(values);
      setLoading(false);
      if (res.ok) {
        Modal.success({
          title: '提交成功',
          content: '已提交成功，请等待处理！',
          onOk: () => {
            if (onBack) history.goBack();
            else {
              formRef.current?.reset();
              getRecipientList();
            }
          },
        });
        // 缓存地址数据
        localStorage.RECIPIENTLIST = RECIPIENTLIST;
      }
    },
  });
  const { mortgageeAddress = [44], subjectType, synchronize = [], materialList, price } = states;

  const getRecipientList = () => {
    if (localStorage.RECIPIENTLIST) {
      // 默认情况下获取上次 保存的地址数据
      try {
        const [recipient] = JSON.parse(localStorage.RECIPIENTLIST);
        formRef.current?.form.setFieldsValue({ recipientList: [recipient || {}] });
      } catch {}
    }
  };

  /**
   * 试算
   */
  const trialCalculation = async (data?: any, areaList?: any[]) => {
    const { carNumber, recipientList } =
      data || formRef.current?.form.getFieldsValue(['carNumber', ['recipientList', 0, 'addressInfo']]);
    if (!regular.carNumber.test(carNumber)) return;
    setLoading('正在试算');
    const { addressInfo } = recipientList[0];
    const carNumberPrefix = addressInfo && stores.unsecured.getCarNumberPrefix(addressInfo, areaList);
    const res = await mortgage.order.confirm({ carNumber, carNumberPrefix });
    setLoading(false);
    if (res.ok) {
      const { materialList, price } = res.data;
      setStates({ materialList, price, carNumberPrefix });
    }
  };
  // 防抖
  const trialCalculationDebounce = debounce(trialCalculation, 1000);

  /**
   * 同步地址
   */
  const onSynchronize = debounce(() => {
    const [recipient] = formRef.current?.form.getFieldValue('recipientList') || [];
    const { addressInfo = [], ...item } = recipient;
    const recipientList = [
      { addressInfo: [...addressInfo], ...item },
      { addressInfo: [...addressInfo], ...item },
    ];
    formRef.current?.form.setFieldsValue({ recipientList });
  }, 1000);

  /**
   * 判断显示状态
   */
  const itemProps = (...names: string[]) => {
    const material = materialList?.find((i: any) => names.includes(i.materialCode));
    if (!material) return { hidden: true };
    if (!material.require) return { required: undefined };
  };

  /**
   * 临时保存
   */
  const temporarySave = async () => {
    let values = formRef.current?.form.getFieldsValue();
    const RECIPIENTLIST = JSON.stringify(values.recipientList);
    values = toValues(values);
    if (!isNotNull(values)) {
      message.error('未填写任何内容');
      return;
    }
    setLoading('正在保存');
    values = toTemporaryValues(values);
    if (temporarySaveId) values.id = temporarySaveId;
    const res = await mortgage.temporary.save(values);
    setLoading(false);
    if (res.ok) {
      // 缓存地址数据
      localStorage.RECIPIENTLIST = RECIPIENTLIST;
      message.success('临时数据已保存成功!');
      if (onBack) history.goBack();
      else {
        formRef.current?.reset();
        getRecipientList();
      }
    }
  };

  return (
    <PageForm
      {...pageFormProps}
      hiddenButtons
      onBack={onBack}
      cols={[7, 16]}
      onValuesChange={({ mortgageeAddress, carNumber, subjectType, synchronize, recipientList }, values) => {
        // 执行试算
        if (carNumber) trialCalculationDebounce();
        // 用于判断抵押人信息展示
        if (subjectType) setStates({ subjectType });
        // 更改地址
        if (mortgageeAddress) setStates({ mortgageeAddress });
        // 同步揽收和邮寄信息
        if (synchronize) {
          const isSynchronize = !!synchronize[0];
          setStates({ synchronize: isSynchronize });
          if (isSynchronize) onSynchronize();
        }
        // 更新揽收信息会同步更新邮寄信息
        if (values.synchronize?.[0] && recipientList) onSynchronize();
      }}
      onFieldsChange={([field]) => {
        const { name } = field || {};
        if (Array.isArray(name) && name[0] === 'recipientList' && name[1] === 0 && name[2] === 'addressInfo') {
          trialCalculationDebounce();
        }
      }}
      scrollToFirstError={false}
      onFinishFailed={({ errorFields }) => {
        for (let name of names) {
          const errorField = errorFields.find((i) => name === i.name[0]);
          if (errorField) {
            formRef.current?.form.scrollToField(errorField.name);
            break;
          }
        }
      }}
    >
      <Steps direction="vertical" className={style.steps} current={0}>
        <Step
          title="基本信息"
          description={<BasicInformation mortgageeAddress={mortgageeAddress} formRef={formRef} />}
        />
        <Step title="机动车信息" description={<MotorVehicleInformation />} />
        <Step
          title="抵押人信息"
          description={<MortgageInformation itemProps={itemProps} subjectType={subjectType} />}
        />
        <Step title="登记证书信息" description={<CertificateInformation itemProps={itemProps} />} />
        <Step
          title="委托材料"
          subTitle={<span className="error">注！下载文档后填写，并拍照上传</span>}
          description={<CommissionedMaterials itemProps={itemProps} />}
        />
        <Step title="合同信息" description={<ContractInformation itemProps={itemProps} />} />
        <Step title="经办人信息" description={<ManagerInformation itemProps={itemProps} />} />
        <Step title="其他信息" description={<OtherInformation itemProps={itemProps} />} />
        <Step
          title="揽收信息"
          subTitle="预审核通过后，办理机构通知揽投员上门收取原件的寄递信息"
          description={<CollectInformation />}
        />
        <Step
          title="邮寄信息"
          subTitle="业务受理成功后，原件回寄给车主的寄递信息"
          description={<MailingInformation synchronize={synchronize} />}
        />
        <Step title="结算" description={<Settlement price={price} temporarySave={temporarySave} />} />
      </Steps>
    </PageForm>
  );
});

export default Page;
