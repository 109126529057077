import { message } from 'antd';
import { dataTool, Request } from 'seasoning';
import { Application } from 'seasoning/es/request/types';
import { stores } from 'stores';

/**
 * 是否生产环境
 */
export const isProduction = window.location.protocol === 'https:';

// ------------------------------- 请求器配置 --------------------------------- //

/**
 * 生产地址
 */
export let host = 'https://shgwgapi.cx580.com';

/**
 * 测试地址
 */
const hostTest = 'https://shgwgapi.cx580.com';

/**
 * 会根据 isProduction 自行变动，需要配置对应的生产和测试地址
 * 测试环境下链接参数 params.host 可强制变更 host
 */
if (!isProduction) {
  const params = dataTool.getParams();
  host = params.host || hostTest;
}

/**
 * 请求器初始化并导出
 */
export const { baseURL, get, post, put, patch, del, upload } = new Request({
  host, // 请求服务地址
  // apiPath: '/api', // 接口公用路径
  console: true, // 打印日志
  defaultConfig: {
    timeout: 10000,
    headers: {
      'Content-Type': Application.Json,
    },
  },
  interceptorsRequest: (config) => {
    if (stores.user.isLogin) {
      config.headers = {
        ...config.headers,
        ...stores.user.headers,
      };
    }
    return config;
  },
  interceptorsResponse: (res, config) => {
    if (res.code === 401) {
      setTimeout(() => message.error('登录超时'));
      stores.user.logout();
    } else {
      if (!res.data && !res.code && !/(地址|超时)/.test(res.errorText)) {
        const { ok, error, errorText, time, ...data } = res;
        res = { ok: true, data, time };
      }
      res.ok || config.noMessage || setTimeout(() => message.error(res.errorText));
    }
    return res;
  },
});
