import { FC } from 'react';
import { InputProps } from 'antd/es/input';
import { TInputNotRequired } from 'seasoning/es/types';
import { Select, Input } from 'common/antd';
import style from './style.module.less';

type TCarNumberProps = TInputNotRequired<string> & Exclude<InputProps, 'value' | 'onChange'>;

/**
 * 输入车牌
 */
export const CarNumber: FC<TCarNumberProps> = ({ value, onChange, ...props }) => {
  const [prefix = '粤', ...val] = [...(value || '')];
  const v = val.join('');

  return (
    <div className={`align-center ${style.carNumber}`}>
      <Select
        className={style.select}
        options={options}
        allowClear={false}
        disabled={props.disabled}
        dropdownMatchSelectWidth={false}
        dropdownClassName={style.prefixDropdown}
        value={prefix}
        onChange={(prefix) => onChange?.(prefix + v)}
      />
      <Input value={v} onChange={(e) => onChange?.(prefix + e.target.value.toUpperCase())} maxLength={7} {...props} />
    </div>
  );
};

const prefix = [...'京津渝沪冀晋辽吉黑苏浙皖闽赣鲁豫鄂湘粤琼川贵云陕甘青蒙桂宁新藏'];
const options = prefix.map((i) => ({ label: i, value: i }));
