//AES加密并编码
export const Encrypt = async (word: string) => {
  const { enc, AES, mode, pad } = await import('crypto-js');
  const key = enc.Utf8.parse('04f8c2b85a354275'); //十六位十六进制数作为密钥
  const iv = enc.Utf8.parse('04f8c2b85a354275');
  let srcs = enc.Utf8.parse(word);
  //AES加密
  let encrypted = AES.encrypt(srcs, key, { iv: iv, mode: mode.CBC, padding: pad.Pkcs7 });
  //base64编码
  let ciphertext = enc.Base64.stringify(enc.Hex.parse(encrypted.ciphertext.toString()));
  return ciphertext;
};
